<app-settingwindow [title]="'Documenti'" (onClose)="closeModal()">
    
    
    <div class="row">
        <div class="col-2">
            <label>Banche</label>
        </div>
        <div class="col-10">
            <table class="table">
                <tr *ngFor="let b of banks">
                    <td>{{b}}</td>
                    <td></td>
                </tr>
            </table>
        </div>
    </div>

</app-settingwindow>
