<app-window [title]="title" >
    <form role="form" id="detailForm">
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group form-group-sm">
                    <label>Nome</label>
                    <input type="text" class="form-control form-control-sm" placeholder="..." name="name"  [(ngModel)]="record.name" >
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group form-group-sm">
                    <label>Email</label>
                    <div class="input-group input-group-sm">
                        <input type="text" class="form-control form-control-sm" placeholder="..." name="email"  [(ngModel)]="record.email" >
                        <div class="input-group-append input-group-append-sm">
                            <span class="input-group-text input-group-text-sm"><i class="fa fa-envelope"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group form-group-sm">
                    <label>Username</label>
                    <div class="input-group input-group-sm">
                        <input type="email" class="form-control form-control-sm" placeholder="..." name="username"  [(ngModel)]="record.username" >
                        <div class="input-group-append input-group-append-sm">
                            <span class="input-group-text input-group-text-sm"><i class="fas fa-user"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group form-group-sm">
                    <label>Password</label>
                    <div class="input-group input-group-sm">
                        <input *ngIf="!currentUser.isSuperUser()" #pw type="text" class="form-control form-control-sm" name="password" placeholder="****" (change)="record.password=pw.value">
                        <input *ngIf="currentUser.isSuperUser()" class="form-control form-control-sm" name="password"  [(ngModel)]="record.password">
                        <div class="input-group-append input-group-append-sm">
                            <span class="input-group-text input-group-text-sm"><i class="fa fa-key"></i></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group form-group-sm">
                    <label>Ruolo</label>
                    <div class="input-group input-group-sm">
                        <select class="form-control form-control-sm" name="role" [(ngModel)]="record.role">
                            <ng-container *ngFor="let ug of usergroups">
                                <option  [ngValue]="ug.name"   *ngIf="currentUser.isAdmin() || currentUser.isSuperUser()"  >{{ug.title}}</option>
                            </ng-container>                            
                        </select>
                        <div class="input-group-append input-group-append-sm">
                            <span class="input-group-text input-group-text-sm"><i class="fas fa-door-open"></i></span>
                        </div>
                    </div>                   
                    <small>
                        <ng-container *ngFor="let ug of usergroups">
                            <i *ngIf="record.role==ug.name" >*<b>{{ug.title}}</b>: {{ug.description}}</i>
                        </ng-container>
                    </small>                   
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group form-group-sm">
                    <label>Stato</label>
                    <select name="enabled" [(ngModel)]="record.enabled"
                            class="form-control form-control-sm" 
                            [class.alert-danger]="record.enabled==0" >
                            <option value="1">Abilitato</option>
                            <option value="0">Disabilitato</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-2" *ngIf="currentUser.isSuperUser()">
                <div class="form-group form-group-sm">
                    <label>ID Anagrafica</label>
                    <div class="input-group input-group-sm">
                        <input type="text" name="id_address" class="form-control form-control-sm" [(ngModel)]="record.id_address">
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="record.params">
            <div  *ngFor="let p of record.params" [class.col-3]="p.type=='number' || p.type=='boolean'"  [class.col-4]="p.type=='text'" [class.col-12]="p.type=='table'">
                <label>{{p.label}}</label>
                <input *ngIf="p.type=='number'" type="number" class="form-control form-control-sm" name="value" [(ngModel)]="p.value" />
                <input *ngIf="p.type=='text'" type="text" class="form-control form-control-sm" name="value" [(ngModel)]="p.value" />
                <select *ngIf="p.type=='boolean'" class="form-control form-control-sm" name="value" [(ngModel)]="p.value">
                    <option value="true">Si</option>
                    <option value="false">No</option>
                </select>
                <ngx-tags-input *ngIf="p.type=='multiple'" displayField="item" class="form-control form-control-sm" [(ngModel)]="p.value" name="value"></ngx-tags-input>
                <table *ngIf="p.type=='table'" class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                    <thead class="bg-secondary">
                        <th *ngFor="let f of p.fields">{{f.label}}</th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let v of p.value">
                            <td *ngFor="let f of p.fields">
                                <input class="form-control form-control-sm" type="text" name="{{f.name}}" [(ngModel)]="v[f.name]"/>
                            </td>
                            <td>
                                <button type="button" class="btn btn-xs btn-danger" (click)="removeTableRecord(p,v);"><i class="fa fa-trash"></i></button>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td><button type="button" class="btn btn-xs btn-success" (click)="addTableRecord(p);"><i class="fa fa-plus"></i></button></td>
                        </tr>

                    </tfoot>
                </table>
            </div>
        </div>
        <div class="row" *ngIf="currentUser.role=='superuser'">
            <div class="col-12">
                <label>Visualizzatore JSON</label>
                <json-editor [data]="record.params" (change)="json_params=$event"></json-editor>
            </div>
            <div class="col-12">
                <button class="btn btn-xs text-white" style="background-color: #3883fa;" (click)="record.params=json_params">Applica</button>                   
            </div>
        </div>
    </form>
    <app-toolbarmodel [model]="this" [showSaveAndClose]="true"></app-toolbarmodel>


</app-window>