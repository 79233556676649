import { Component, OnInit } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { Extension } from 'projects/core/src/include/parameters';
import { CSettingWindow, ISettingWindow } from 'projects/core/src/include/settingwindow';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css'
})
@ISettingWindow.register
export class DocumentsSettingsComponent extends CSettingWindow implements OnInit {
  
  module:Extension;
  banks:any=[];
  ngOnInit(): void {
    this.module=Globals.parameters.get("documents");
    this.banks=this.module.getParam("banks",[]);
    
  }
  


  


}
