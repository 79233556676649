
<ng-container *ngIf="field.type=='separator'">
    <div class="form-group">
        <h5 *ngIf="field.label" class="text-info">{{field.label}}</h5>
        <hr/>
    </div>
</ng-container>

<div class="form-group row field">

    <div class="col-md-2 px-md-0" *ngIf="field.show_label">
        <label [class.text-compiled]="field.value && field.value!=''">
            <span *ngIf="field.label==''">{{field.name}}</span>
            <span *ngIf="field.label!=''">{{field.label}}</span>
            <span *ngIf="field.required==true" class="text-danger help" title="Obbligatorio">&nbsp;*</span>
        </label>
    </div>

    <div class="col-lg-10 mb-1 pr-lg-0" [class.col-md-12]="!field.show_label">        
        
        <ng-container *ngIf="field.type=='text'">
            <input type="text" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
        </ng-container>

        <ng-container *ngIf="field.type=='number'">
            <input type="number" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
        </ng-container>

        <ng-container *ngIf="field.type=='currency'">
            <div class="input-group input-group-sm">
                <input type="number" min="0.00" max="10000000.00" step="0.01" [disabled]="disabled" class="form-control form-control-sm text-right" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
                <div class="input-group-append">
                    <span class="input-group-text">&euro;</span>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="field.type=='textarea'">
            <textarea type="text" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value"></textarea>
        </ng-container>

        <ng-container *ngIf="field.type=='date'">
            <div class="input-group input-group-sm">
                <input type="date" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">            
                <div class="input-group-append" *ngIf="field.defaultvalue">
                    <button class="btn btn-default text-primary" (click)="DateDefaultValue(field)">Calcola data</button>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="field.type=='time'">
            <input type="time" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
        </ng-container>

        <ng-container *ngIf="field.type=='boolean'">
            <select [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
                <option value="0">No</option>
                <option value="1">Si</option>
            </select>
        </ng-container>

        <ng-container *ngIf="field.type=='checkbox'">
            <input type="checkbox" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
        </ng-container>

        <ng-container *ngIf="field.type=='select'">
            <select [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
                <option value="{{o}}" *ngFor="let o of field.params">{{o}}</option>
            </select>
        </ng-container>

        <ng-container *ngIf="field.type=='file'">        
            <ng-container *ngIf="isFileMultiple(field)">
                <ul class="list-group">
                    <ng-container *ngFor="let f of parseMultipleFile(field.value)">                    
                        <li class="list-group-item">
                            <div class="row">
                                <div class="col-9">
                                    <i class="fa {{getFileTypeIcon(getExtension(f))}} mr-2"></i>{{getFilename(f)}}
                                </div>
                                <div class="col-3 px-0 text-right">
                                    <button class="btn text-primary btn-sm mr-1" *ngIf="field.value" (click)="downloadFile(f)" title="Visualizza"><i class="far fa-eye"></i></button>
                                    <button *ngIf="!disabled" class="btn text-danger btn-sm" (click)="deleteFile(field,f)" title="Rimuovi"><i class="fa fa-times"></i></button>
                                </div>
                            </div>
                        </li>
                    </ng-container>
                </ul>
                <button *ngIf="!disabled" class="btn btn-block btn-outline-success btn-sm" (click)="addFile(field)"><i class="fa fa-upload mr-2"></i>Aggiungi File</button>
            </ng-container>

            <ng-container *ngIf="!isFileMultiple(field)">
                <div class="input-group input-group-sm">                
                    <input type="text" [readonly]="true" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
                    <div class="input-group-append">
                        <button class="btn btn-secondary btn-sm" *ngIf="!disabled" (click)="addFile()">Seleziona</button>
                        <button class="btn btn-info btn-sm" *ngIf="field.value" (click)="downloadFile(field.value)" title="Visualizza"><i class="far fa-eye"></i></button>
                    </div>
                </div>
            </ng-container>
          
        </ng-container>

        <!-- 
        <ng-container *ngIf="field.type=='textarea'">
            <textarea type="text" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value"></textarea>
        </ng-container>
        <ng-container *ngIf="field.type=='date'">
            <div class="input-group">
                <input type="date" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
            </div>
        </ng-container>
        -->

        <ng-container *ngIf="field.type=='photo'">
            <br/>
            <ng-container *ngIf="isPhotoMultiple(field)">
                <div class="row">
                    <ng-container *ngFor="let i of parseMultiplePhoto(field.value)">
                        <div class="col-3">
                            <img class="img-thumbnail" style="max-width: 100px" src="{{pathImage(i)}}">
                            <button *ngIf="!disabled" style="position: absolute;left: 5px;top: -7px;"class="btn btn-danger btn-sm" (click)="deletePhoto(field,i)"><i class="fa fa-times"></i></button>
                        </div>
                    </ng-container>
                </div>
                <button *ngIf="!disabled" class="mt-2 btn btn-block btn-success" (click)="addPhoto(field)"><i class="fa fa-camera mr-2"></i>Aggiungi foto</button>
            </ng-container>

            <ng-container *ngIf="!isPhotoMultiple(field)">
                <div class="row">
                    <div class="col-3">
                        <img *ngIf="field.value!=''" class="img-thumbnail" style="max-width: 100px" src="{{pathImage(field.value)}}" >
                        <button *ngIf="field.value!='' && !disabled" style="position: absolute;left: 5px;top: -7px;"class="btn btn-danger btn-sm" (click)="field.value=''"><i class="fa fa-times"></i></button>
                    </div>
                </div>
                <button *ngIf="field.value!='' && !disabled" class="mt-2 btn btn-block btn-info" (click)="takePhoto(field);"><i class="fa fa-camera mr-2"></i>Cambia foto</button>
                <button *ngIf="field.value=='' && !disabled" class="mt-2 btn btn-block btn-success" (click)="takePhoto(field);"><i class="fa fa-camera mr-2"></i>Scatta una foto</button>
            </ng-container>       
        </ng-container>

       

        <ng-container *ngIf="field.type=='address'">
            <div class="input-group input-group-sm">
                <input type="text" disabled="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" value="{{field.value.name}}">
                <div class="input-group-append">
                    <button class="btn btn-default text-primary" *ngIf="!disabled" (click)="selectAddress(field)">Scegli</button>
                    <button class="btn btn-default text-primary" *ngIf="!disabled && field.value.id" (click)="openAddress(field.value.id)">Info</button>            
                </div>
            </div>       
        </ng-container>

        <ng-container *ngIf="field.type=='table'">
            <ng-container *ngFor="let section of field.params">
                
                <div class="row">
                    <div class="col-12">
                        <div class="input-group">
                            <input type="text" class="form-control form-control-sm" placeholder="cerca nella tabella ..." [(ngModel)]="searchTableText" (keyup.enter)="searchTable(field.value)" >
                            <div class="input-group-append">
                                <button type="button" class="btn" (click)="searchTable(field.value)"><i class="fa fa-search"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <table class="table table-sm table-responsive table-striped table-field">
                    <thead>
                        <ng-container *ngFor="let f of section.fields">
                            <th *ngIf="f.show_list==1">
                                <span *ngIf="f.label">{{f.label}}</span>
                                <span *ngIf="!f.label">{{f.name}}</span>
                                
                            </th>
                        </ng-container>
                        <th></th>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let v of field.value">
                            <tr *ngIf="!v.hide" >
                                <ng-container *ngFor="let f of v.fields">
                                    <td *ngIf="checkFieldShow(f,section.fields)">
                                        <app-field [field]="f"></app-field>
                                    
                                    </td>
                                </ng-container>
                                <td>
                                    <button class="btn btn-sm btn-secondary" (click)="openDetailTable(v,section.fields)"><i class="fa fa-pen"></i></button>
                                    
                                    <button class="btn btn-sm btn-danger" (click)="deleteRowTable(field.value,v)"><i class="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <button class="btn btn-sm btn-success" (click)="addRowTable(field.value,section.fields)"><i class="fa fa-plus mr-2"></i>Aggiungi</button>
                <button class="btn btn-sm btn-info" (click)="openImportModal(field)"><i class="fa fa-plus mr-2"></i>Importa</button>
            
            </ng-container>
        </ng-container>

        <ng-container *ngIf="field.description">
            <small class="text-gray"><i title="{{field.description}}" class="fas fa-info-circle mr-1"></i><i [innerHTML]="field.description"></i></small>    
        </ng-container>    
    </div>        

</div>





<ng-template #modalTableDetail>
    <div class="modal-header">

    </div>
    <div class="modal-body">
        <div class="row">
            
            <ng-container *ngFor="let f of fields_detail.fields">
                <div class="col-md-2" >
                    <label>
                        <span *ngIf="f.label">{{f.label}}</span>
                        <span *ngIf="!f.label">{{f.name}}</span>
                    </label>
                </div>
                <div class="col-md-10">
                    <app-field [field]="f"></app-field>
                </div>
                
                
            
            
            </ng-container>
           
        </div>
    </div>
   <div class="modal-footer">
        <button class="btn btn-sm btn-secondary" (click)="closeDetailTable()">Chiudi</button>
   
    </div>


</ng-template>



<ng-template #modalImportTable>
    <div class="modal-header">
        Importa dati
    </div>
    <div class="modal-body">
        <label>Formato dati</label>
        <select class="form-control" [(ngModel)]="dataFromFormat">
            <option [ngValue]="'json'">JSON</option>
        </select>
        <textarea class="form-control" [(ngModel)]="dataFromImport"></textarea>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn-success" (click)="importData()">Importa</button>
        <button type="button" class="btn btn-sm btn-secondary" (click)="closeImportModal()">Annulla</button>
    </div>
</ng-template>