<app-window [title]="titleWindow" [model]="this">
    
    <!-- ALERT -->
    <div class="row mb-3" *ngIf="verifyBillingAddress(record)!=''">
        <div class="col-12">
            <i class="float-left h-100 pr-2 text-warning fas fa-exclamation-triangle fa-2x"></i>
            <div class="float-left">
                <small>
                    <b>ATTENZIONE:</b>
                    <div *ngFor="let v of verifyBillingAddress(record)"><span class="text-red">&#10008;</span> {{v}}</div>
                </small>
            </div>
        </div>
    </div>
    <div class="row mb-3" *ngIf="record.sdi_status==2 || record.sdi_status==4">
        <div class="col-12">
            <i class="float-left h-100 pr-2 text-gray fas fa-exclamation-circle fa-2x"></i>
            <div class="float-left">
                <small>
                    <b>NOTA:</b>
                    <div><span class="">Alcuni campi sono disabilitati perchè il documento risulta oramai confermato sullo SdI.</span></div>
                </small>
            </div>
        </div>
    </div>

    <form role="form" id="detailForm">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item ">
                <a class="nav-link" [class.active]="tab==2" (click)="tab=2" >
                    <i class="fas fa-clipboard"></i><span class="d-none d-sm-inline-block ml-2">Generale</span></a>
            </li>
            <li class="nav-item ">
                <a class="nav-link" *ngIf="record.typedocument!=6" [class.active]="tab==3" (click)="tab=3">
                    <i class="fas fa-address-card"></i><span class="d-none d-sm-inline-block ml-2">Intestazione <small><span class="badge border" *ngIf="record.addressItem && record.addressItem.role=='PA'">PA</span></small></span>
                </a>
            </li>
            <li class="nav-item ">
                <a class="nav-link" [class.active]="tab==12" (click)="tab=12">
                    <i class="far fa-file-alt"></i><span class="d-none d-sm-inline-block ml-2">Altri dati</span></a>
            </li>
            <li class="nav-item" *ngIf="record.typedocument==2 || record.typedocument==5">
                <a class="nav-link" [class.active]="tab==33" (click)="tab=33">
                    <i class="fas fa-truck"></i><span class="d-none d-sm-inline-block ml-2">Spedizione</span></a>
            </li>
            <!--<li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabDD6" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="far fa-sticky-note"></i><span class="d-none d-sm-inline-block ml-2">Note</span></a>
            </li>-->
            <li class="nav-item" *ngIf="record.typedocument!=10">
                <a class="nav-link" [class.active]="tab==7" (click)="tab=7" >
                    <i class="fas fa-money-bill-alt"></i><span class="d-none d-sm-inline-block ml-2">Pagamenti <small><span class="badge badge-danger" *ngIf="record.cashflow && record.cashflow.length>0">{{record.cashflow.length}}</span></small></span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==5" (click)="tab=5">
                    <i class="fas fa-paste"></i><span class="d-none d-sm-inline-block ml-2">Doc. collegati <small><span class="badge badge-warning" *ngIf="record.documentlinked && record.documentlinked.length>0">{{record.documentlinked.length}}</span></small></span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==8" (click)="tab=8">
                    <i class="far fa-clock"></i><span class="d-none d-sm-inline-block ml-2">Cronologia</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" *ngIf="checkPermissions('showUsers')" [class.active]="tab==9" (click)="tab=9">
                    <i class="fas fa-user-tie "></i><span class="d-none d-sm-inline-block ml-2">Operatore</span></a>
            </li>
            <li class="nav-item" *ngIf="(record.typedocument==1 || record.typedocument==2 || record.typedocument==12 || record.typedocument==3)">
            <!-- <li class="nav-item" *ngIf="record.typedocument!=10"> -->
                <a class="nav-link" [class.active]="tab==10" (click)="tab=10">
                    <i class="fas fa-share-square"></i>
                    <span class="d-none d-sm-inline-block ml-2">SdI 
                        <small>
                            <i *ngIf="record.sdi_status=='1'" class="text-warning fa fa-clock"></i>
                            <i *ngIf="record.sdi_status=='2'" class="text-success fa fa-check"></i>
                            <i *ngIf="record.sdi_status=='3'" class="text-danger fa fa-exclamation-triangle"></i>
                            <i *ngIf="record.sdi_status=='4'" class="text-primary fa fa-check"></i>
                        </small>
                    </span>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==11" (click)="tab=11">
                    <i class="fas fa-hdd"></i><span class="d-none d-sm-inline-block ml-2">Files <small><span class="badge badge-info" *ngIf="record.files && record.files.length>0">{{record.files.length}}</span></small></span></a>
            </li>

            <li class="nav-item" *ngIf="record.id_type==1 && checkPermissions('showAnalytics')">
                <a class="nav-link" [class.active]="tab==13" (click)="tab=13;getAnalytics();">
                    <i class="fa fa-chart-line"></i><span class="d-none d-sm-inline-block ml-2">Statistiche</span></a>
            </li>
        </ul>
    
    <div class="p-2" >
        <div class="tab-content" id="myTabContent">            
            <!-- GENERALE -->
            <div class="tab-pane fade show active" *ngIf="tab==2">
                <div class="row">
                    <div class="col-12 col-lg-8">
                        <div [class.section_disabled]="record.sdi_status==2 || record.sdi_status==4" class="form-group row">
                            <div class="col-lg-2"><label>Numerazione</label></div>
                            <div class="col-lg-4">
                                <div class="input-group input-group-sm">
                                    <input type="text" class="form-control"  name="reference" placeholder="..." [(ngModel)]="record.reference " >
                                    <ng-container *ngIf="record.id_type==1" >
                                        <select class="form-control" name="referenceprefix" placeholder="..." [(ngModel)]="record.referenceprefix ">
                                            <option *ngFor="let s of sectionals"  value="{{s['code']}}">{{s['code']}}&ensp;{{s['label']}}</option>
                                        </select>
                                        <div class="input-group-append">
                                            <a title="Suggerisci numerazione" *ngIf="record.id==0" class="input-group-text btn btn-sm" 
                                                (click)="getNextNumeration()"><i class="fas fa-calculator"></i></a>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-lg-2 text-lg-right"><label>Data</label></div>
                            <div class="col-lg-4">
                                <input [class.bg-warning]="checkAfterToday(record.date)" class="form-control form-control-sm" type="date"  name="date" [(ngModel)]="record.date"/>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-2"><label><span *ngIf="id_type==1">Cliente</span><span *ngIf="id_type==2">Fornitore</span></label></div>
                            <div class="col-12 col-lg-10">
                                <div class="small-box bg-light mb-0">
                                    <app-selectfieldcustom (OnExplore)="selectAddress()" (OnRecordSelected)="OnSelectAddress($event)" (OnRecordView)="OnViewAddress($event)"
                                        [showViewButton]="isModuleEnabled('address')"
                                        [showSearchButton]="isModuleEnabled('address')"
                                        [recordSelected]="record.addressItem"
                                        [service]="addressesService"
                                        [fieldRender]="'name'"
                                        [class]="'form-control form-control-sm'"></app-selectfieldcustom>
                                    <div class="p-1" *ngIf="record.addressItem && record.addressItem.id>0">
                                        <small>
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <small>
                                                        <ng-container *ngIf="record.addressItem.address || record.addressItem.city">
                                                            <b>INDIRIZZO:</b><br>
                                                            {{record.addressItem.address}}<br>
                                                            {{record.addressItem.zip}} {{record.addressItem.city}} {{record.addressItem.country}}<br>
                                                        </ng-container>
                                                        <span class="text-uppercase" *ngIf="record.addressItem.tax_code">COD. FISC.: {{record.addressItem.tax_code}}<br></span>
                                                        <span *ngIf="record.addressItem.vat_number">P.IVA: {{record.addressItem.vat_number}}</span>
                                                    </small>
                                                </div>
                                                <div class="col-lg-6 border-left" *ngIf="record.addressItem.contacts.length > 0">
                                                    <small>
                                                        <b>CONTATTI:</b><br>
                                                        <span *ngFor="let c of record.addressItem.contacts">{{c.value}}<br></span>
                                                    </small>
                                                </div>
                                            </div>
                                        </small>
                                        <div class="icon"><i class="fa fa-user"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-2"><label>Causale</label></div>
                            <div class="col-lg-5">
                                <div class="input-group input-group-sm">
                                    <select name="description" class="form-control form-control-sm" name="description" [(ngModel)]="record.description">
                                        <option *ngFor="let d of listDescription" [value]="d.description">{{d.description}}</option>
                                    </select>
                                    <div class="input-group-append" *ngIf="checkPermissions('editCausals')">
                                        <a type="button" class="btn btn-default" (click)="openModalCausals()" title="Apri gestione causali"><i class="fa fa-cog"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5">
                                <button type="button" *ngIf="record.state==1" class="btn btn-success btn-sm btn-block" (click)="confirmDocument()">Conferma Documento</button>
                            </div>
                        </div>
                        <div class="form-group row" >
                            <div class="col-lg-2">
                                <label>Descrizione<i class="fas fa-info-circle ml-2 text-gray help" 
                                    title="Attenzione: eventuali caratteri speciali verrano automaticamente sostituiti o eliminati durante il salvataggio."></i></label>
                            </div>
                            <div class="col-lg-10">
                                <input type="text" class="form-control form-control-sm" name="description2" [(ngModel)]="record.description2">
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="record.typedocument==10">
                            <div class="col-lg-2"><label>Da</label></div>
                            <div class="col-lg-4">
                                <div class="input-group input-group-sm">
                                    <p class="form-control form-control-sm">
                                        <ng-container *ngIf="record.shop_start">{{record.shop_start.name}}</ng-container>
                                    </p>
                                    <div class="input-group-append">
                                        <a type="button" class="btn  btn-sm btn-secondary" (click)="selectShop()">Seleziona</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 text-lg-right"><label>il</label></div>
                            <div class="col-lg-4">
                                <input type="datetime-local" class="form-control form-control-sm" name="date_shop_start"
                                    [ngModel]="record.date_shop_start | date:'yyyy-MM-ddTHH:mm'" (ngModelChange)="record.date_shop_start = $event">
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="record.typedocument==10">
                            <div class="col-lg-2"><label>A</label></div>
                            <div class="col-lg-4">
                                <div class="input-group input-group-sm">
                                    <p class="form-control form-control-sm">
                                        <ng-container *ngIf="record.shop_end">{{record.shop_end.name}}</ng-container>
                                    </p>
                                    <div class="input-group-append">
                                        <a type="button" class="btn btn-sm btn-secondary" (click)="selectShop(false)">Seleziona</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 text-lg-right"><label>il</label></div>
                            <div class="col-lg-4">
                                <input type="datetime-local" class="form-control form-control-sm" name="date_shop_end" 
                                    [ngModel]="record.date_shop_end | date:'yyyy-MM-ddTHH:mm'" (ngModelChange)="record.date_shop_end = $event">
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4" style="font-size:80%">
                        <div class="row">
                            <div class="col-12"><b>Riepilogo</b></div>
                            <div class="col-12">
                                <table class="table table-sm ">
                                    <tr>
                                        <td>Imponibile beni</td>
                                        <td width="100px" class="text-right"><strong>{{total.netrows | currency:"&euro; "}}</strong></td>
                                        <td width="10px">+</td>
                                    </tr>                                    
                                    <tr>
                                        <td>Sconto totale sui beni</td>
                                        <td width="100px" class="text-right">{{total.discountrows | currency:"&euro; "}}</td>
                                        <td width="10px">-</td>
                                    </tr>
                                    <tr *ngIf="user.isSuperUser() || record.id_type==2 || enablewelfarefund">
                                        <td><input type="checkbox" [(ngModel)]="record.welfarefundenabled" name="welfarefundenable" (change)="welfareFundSwitchEnabled()">
                                            &ensp;Cassa previdenziale&ensp;
                                            <button class="btn btn-sm" *ngIf="record.welfarefundenabled" title="Calcola i valori della cassa previdenziale" (click)="calculateWelfarefund()">
                                                <i class="fa fa-calculator" ></i></button>
                                        </td>
                                        <td width="100px" class="text-right">
                                            <span *ngIf="record.welfarefundenabled && getSpecialProduct('##WELFAREFUND')">
                                                {{getSpecialProduct("##WELFAREFUND").netprice*(getSpecialProduct("##WELFAREFUND").percentual/100) | currency:"EUR"}}
                                            </span>
                                        </td>
                                        <!--<td width="100px" class="text-right">{{getSpecialProduct("##WELFAREFUND")}} total.welfarefund | currency:"&euro; "}}</td> -->
                                        <td width="10px">+</td>
                                    </tr>                                   
                                    <!-- <tr> DEPRECATE
                                        <td style="vertical-align:middle;">Spese di trasporto</td>
                                        <td [class.section_disabled]="record.sdi_status==2 || record.sdi_status==4"  width="100px" class="text-right">
                                            <input type="number" class="text-right form-control form-control-sm float-right filter-width-1" style="height: 1.5rem;" min="0" step="0.01" name="shipping_cost" placeholder="..." [(ngModel)]="record.shipping_cost" (change)="calculateTotal()"></td>
                                        <td style="vertical-align:middle;" width="10px">+</td>
                                    </tr>
                                    <tr>
                                        <td style="vertical-align:middle;">Spese di imballaggio</td>
                                        <td [class.section_disabled]="record.sdi_status==2 || record.sdi_status==4" width="100px" class="text-right">
                                            <input type="number" class="text-right form-control form-control-sm float-right filter-width-1" style="height: 1.5rem;" min="0" step="0.01" name="packaging_cost" placeholder="..." [(ngModel)]="record.packaging_cost" (change)="calculateTotal()"></td>
                                        <td style="vertical-align:middle;" width="10px">+</td>
                                    </tr> -->
                                    <tr>
                                        <td>Imponibile</td>
                                        <td class="text-right">{{total.net | currency:"&euro; "}}</td>
                                        <td width="10px"></td>
                                    </tr>
                                    <tr>
                                        <td>Imposta</td>
                                        <td class="text-right">{{total.tax | currency:"&euro; "}}</td>
                                        <td width="10px">+</td>
                                    </tr>
                                    <tr>
                                        <td style="vertical-align:middle;"><strong>TOTALE</strong></td>
                                        <td *ngIf="previousImport==false" class="text-right"><strong>{{total.gross | currency:"&euro; "}}</strong></td>
                                        <td [class.section_disabled]="record.sdi_status==2 || record.sdi_status==4" *ngIf="previousImport==true" class="text-right">
                                            <input type="number" class="form-control form-control-sm pr-0" style="height: 1.5rem;" min="0" step="0.01" name="amount" placeholder="..." [(ngModel)]="record.amount" >
                                        </td>
                                        <td style="vertical-align:middle;" width="10px">=</td>
                                    </tr>
                                    <tr>
                                        <td><input type="checkbox" [(ngModel)]="record.withholdingtaxenabled" name="withholdingtaxenabled" (change)="withholdingtaxSwitchEnabled();calculateTotal()">
                                            &ensp;Ritenuta d'acconto&ensp; <span *ngIf="getSpecialProduct('##WITHHOLDINGTAX')">({{getSpecialProduct('##WITHHOLDINGTAX').percentual | number:'1.0'}} %)</span>
                                           <!-- <input type="number" style="height: 1.5rem; width:70px" class="d-inline text-right form-control form-control-sm" name="total_withholdingtaxpercentual" size="4" [(ngModel)]="withholdingtaxPercentual" (change)="calculateTotal()"> %</td>-->
                                        <td width="100px" class="text-right">{{total.withholdingtax | currency:"&euro; "}}</td>
                                        <td width="10px">+</td>
                                    </tr>
                                    <tr>
                                        <td style="vertical-align:middle;"><strong>DA PAGARE</strong></td>
                                        <td *ngIf="previousImport==false" class="text-right">
                                            <strong>{{record.total_pay | currency:"&euro; "}}</strong>
                                            <!-- <ng-container *ngIf="record.id_type==2">
                                                <input type="number" style="height: 1.5rem; width:70px" class="d-inline text-right form-control form-control-sm" name="total_pay" [(ngModel)]="record.total_pay">
                                            €</ng-container> -->
                                        </td>                                        
                                        <td style="vertical-align:middle;" width="10px">=</td>
                                    </tr>
                                    <ng-container *ngIf="record.typedocument!=4 && record.typedocument!=5 && record.typedocument!=7 && record.typedocument!=8 && record.typedocument!=10 && record.typedocument!=13 ">
                                        <tr [class.text-success]="total.gross - abs(record.paid) < 0.01">
                                            <td><strong>PAGATO</strong></td>
                                            <td class="text-right"><strong>{{abs(record.paid) | currency:"&euro; "}}</strong></td>
                                            <td></td>
                                        </tr>
                                        <tr class="alert-danger" *ngIf="total.gross - abs(record.paid) >= 0.01">
                                            <td><strong>RIMANENZA </strong><small *ngIf="record.id_documentsrule==4">(IVA versata dal Cliente)</small></td>
                                            <td *ngIf="record.id_documentsrule!=4" class="text-right"><strong>{{record.total_pay - abs(record.paid) | currency:"&euro; "}}</strong></td>
                                            <td *ngIf="record.id_documentsrule==4" class="text-right"><strong>{{total.net - abs(record.paid) | currency:"&euro; "}}</strong></td>
                                            <td></td>
                                        </tr>
                                    </ng-container>
                                </table>
                                
                            </div>
                        </div>
                    </div>
                
                    <div class="col-12">
                        <div *ngIf="idDocumentDetailSelected.length > 0">
                            <a class="btn btn-outline-secondary btn-xs mb-1" (click)="moveIntoAnotherDocument()"><i class="fas fa-arrows-alt mr-2"></i>Sposta le voci in un altro documento</a>
                        </div>
                        <div >
                            <app-goods [iswithholdingtax]="record.withholdingtaxenabled" [locked]="record.sdi_status==2 || record.sdi_status==4" [canNewProduct]="true" [viewmode]="2" [type]="record.id_type" [showSelect]="true" [id_document]="record.id" [id_address]="IDAddress" [address]="record.addressItem" [list]="record.details" [checkSN]="true" [autoSelect]="false" (listUpdate)="record.details=$event;checkRule();calculateTotal();checkSupplier()" (onSelected)="idDocumentDetailSelected=$event;" (onWithHoldingTaxEnabled)="record.iswithholdingtax=$event;calculateTotal();"></app-goods>
                        </div>
                        <div class="form-group row mt-4 mb-0" *ngIf="checkPermissions('editInventories') || checkDocumentEnableInventory()">
                            <div class="col-lg-2"><label>Magazzino </label></div>
                            <div class="col-lg-10">
                                <select class="form-control form-control-sm"  name="enable_inventory" [(ngModel)]="record.enable_inventory">
                                    <option value="1">Movimenta i beni nel magazzino</option>
                                    <option value="0">Non movimentare i beni nel magazzino</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row mt-4 mb-0" >
                            <div class="col-lg-2">
                                <label class="help">Note del documento<i class="fas fa-info-circle ml-2 text-gray help" 
                                        title="Puoi utilizzare le Tag HTML. Per esempio scrivi <br> per andare a capo o racchiudi il testo 
                                        tra <b>...</b> per formattarlo in grassetto."></i></label>
                            </div>
                            <div class="col-lg-10">
                                <textarea class="form-control form-control-sm" row="3" name="note2" [(ngModel)]="record.note2"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- INTESTAZIONE-->
            <div class="tab-pane fade show active" *ngIf="tab==3">
                <div [class.section_disabled]="record.sdi_status==2 || record.sdi_status==4">                    
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Ragione sociale</label>
                        </div>
                        <div class="col-lg-10">
                            <input [class.section_disabled]="addressDisabled" type="text" class="form-control form-control-sm" name="name" placeholder="..." #name="ngModel" [(ngModel)]="record.name">
                            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                                <div *ngIf="name.errors.required">Campo obbligatorio.</div>
                            </div>
                        </div>
                    </div>                    
                    <app-cities [disabled]="addressDisabled" name="city"  [address]="record.address" [country]="record.country" [city]="record.city"  [zip]="record.zip" (onEntrySelected)="record.address=$event.address;record.city=$event.city;record.country=$event.country;record.zip=$event.zip;"></app-cities>
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Residente in</label>
                        </div>
                        <div class="col-lg-4">
                            <div class="input-group input-group-sm">
                                <app-nations [disabled]="addressDisabled" [class]="'form-control form-control-sm'" [nation]="record.nation" (onSelect)="record.nation=$event"></app-nations>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Partita IVA</label>
                        </div>
                        <div class="col-lg-3">
                            <div class="input-group input-group-sm" [class.section_disabled]="addressDisabled" >
                                <app-vat-number [vat_number]="record.vat_number" (onTextChange)="record.vat_number=$event"></app-vat-number>
                            </div>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                            <label>Codice fiscale</label>
                        </div>
                        <div class="col-lg-5">
                            <div class="input-group input-group-sm" [class.section_disabled]="addressDisabled" >
                                <app-tax-code [tax_code]="record.tax_code" (onTextChange)="record.tax_code=$event"></app-tax-code>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>SdI</label>
                        </div>
                        <div class="col-lg-2">
                            <div class="input-group input-group-sm">
                                <input [class.section_disabled]="addressDisabled" maxlength="7" [class.is-invalid]="record.sdi.length<6"  title="inserisci un codice di 7 lettere (6 per PA)"  type="text" class="form-control "  name="sdi" [(ngModel)]="record.sdi">
                            </div>
                        </div>
                        <div class="col-lg-3 text-lg-right">
                            <label>PEC</label>
                        </div>
                        <div class="col-lg-5">
                            <div class="input-group input-group-sm">
                                <input [class.section_disabled]="addressDisabled"  title="inserisci un indirizzo PEC"  type="text" class="form-control " name="pec" [(ngModel)]="record.pec">
                            </div>
                        </div>
                    </div>
                    <div class="mt-1">
                        <a *ngIf="record.sdi_status!=2 && record.sdi_status!=4" type="button" class="btn btn-primary btn-sm float-right" 
                            (click)="openModalEditAddress()"><i class="fas fa-pencil-alt mr-2"></i>Modifica intestazione</a>
                    </div>
                </div>
            </div>


            <!-- ALTRI DATI-->
            <div class="tab-pane fade show active" *ngIf="tab==12">
                <div [class.section_disabled]="record.sdi_status==2 || record.sdi_status==4" class="form-group row">
                    <div class="col-lg-2 mb-1">
                        <label>Codice</label>
                    </div>
                    <div class="col-lg-4 mb-1">
                        <input type="text" readonly="true" name="code" class="form-control form-control-sm" placeholder="..." #code="ngModel" [(ngModel)]="record.code" >
                    </div>
                    <div class="col-lg-2 mb-1 text-lg-right"><label>Tipo di documento</label></div>
                    <div class="col-lg-4 mb-1">
                        <select  class="form-control form-control-sm" [(ngModel)]="record.typedocument" name="typedocument" #type="ngModel" (change)="getNextNumeration(true);getRules();">
                            <option value="1"                       >Fattura</option>
                            <option value="2"                       >Fattura accompagnatoria</option>
                            <option value="12"                      >Fattura acconto</option>
                            <option value="3" *ngIf="id_type==1"    >Nota di credito</option>
                            <option value="3" *ngIf="id_type==2"    >Nota di debito</option>
                            <option value="4"                       >Preventivo</option>
                            <option value="5"                       >DDT</option>
                            <option value="10"                      >Trasferimento</option>
                            <option value="6" *ngIf="id_type==1"    >Scontrino</option>
                            <option value="7" *ngIf="id_type==1"    >Ordine cliente</option>
                            <option value="8" *ngIf="id_type==2"    >Ordine fornitore</option>
                            <option value="9"                       >Non fiscale</option>
                            <option value="11"                      >Documento amministrativo</option>
                            <option value="13"                      >Inventario</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-lg-2 mb-1"><label>Note interne</label></div>
                    <div class="col-lg-4 mb-1">
                        <textarea  class="form-control form-control-sm" rows="2" name="note" [(ngModel)]="record.note" placeholder="..."></textarea>
                    </div>
                    <div class="col-lg-2  text-lg-right mb-1"><label>Riferimento</label></div>
                    <div class="col-lg-4 mb-1">
                        <div class="p-2 border">
                            <app-reference [multiple]="true" (recordConnected)="connectReference($event)" [table]="record.table" [showConnectBtn]="true" [showDisconnect]="true" [reference]="record.referencedocument" [mode]="'detail'"></app-reference>
                        </div>
                    </div>
                </div>
                <!--
                <div class="form-group row">
                    <div class="col-lg-2 mb-1 "><label>Tag</label></div>
                    <div class="col-lg-10 mb-1">
                        <ngx-tags-input displayField="tag" class="form-control form-control-sm" [(ngModel)]="record.tags" name="tags"></ngx-tags-input>
                    </div>
                  
                </div>
            -->
                <div class="form-group row mb-1">
                    <div class="col-12">
                        <h5>Contabilità e fiscali</h5>
                    </div>
                    <div class="col-lg-2 mb-1"><label>Stato</label></div>
                    <div class="col-lg-4 mb-1">
                        <select class="form-control form-control-sm" name="state" [(ngModel)]="record.state" title="Stato: {{record.state}}"
                            [class.alert-warning]="record.state==1 || record.state==6" 
                            [class.alert-success]="record.state==2" 
                            [class.alert-danger]="record.state==3" 
                            [class.alert-info]="record.state==4 || record.state==5" >
                            <option value="1">da Confermare</option>
                            <option value="2">Confermato</option>
                            <option value="3">Annullato</option>
                            <option value="4">Ordinato</option>
                            <option value="5">Spedito</option>
                            <option value="6">Parziale</option>
                        </select>
                    </div>
                    <ng-container *ngIf="isModuleEnabled('financialaccounts')">
                        <div class="col-lg-2 mb-1 text-lg-right">
                            <label>Conti contabili</label>
                        </div>
                        <div class="col-lg-4 mb-1">
                            <app-financialaccount [id_financialaccount]="record.id_financialaccount" (selected)="record.id_financialaccount=$event['id']"></app-financialaccount>
                        </div>
                    </ng-container>
                </div>
                <div [class.section_disabled]="record.sdi_status==2 || record.sdi_status==4" class="form-group row mb-1">
                    <!---
                    <div class="col-lg-2 mb-1"><label>Regime IVA</label></div>
                    <div class="col-lg-4 mb-1">
                        <select class="form-control form-control-sm" [(ngModel)]="record.id_documentsrule" name="id_documentsrule" #type="ngModel" (change)="setRule($event)">
                            <option *ngFor="let r of rules" [ngValue]="r.id">{{r.name}}</option>
                        </select>
                    </div>
                     -->
                     <ng-container *ngIf="record.typedocument==1 || record.typedocument==2 || record.typedocument==3 || record.typedocument==12">
                        <div class="col-lg-2 mb-1">
                            <label>
                                <span *ngIf="id_type==1">Destinazione</span>
                                <span *ngIf="id_type==2">Provenienza</span>
                            </label>
                        </div>
                        <div class="col-lg-4 mb-1 ">
                            <div class="btn-group btn-group-sm w-100">
                                <button [class.alert-info]="record.private==0" type="button" class="btn btn-default border btn-sm" (click)="record.private=0">
                                    <span *ngIf="id_type==2">da </span>
                                    <span *ngIf="id_type==1">verso </span>
                                    Italia</button>
                                <button [class.alert-info]="record.private==2" type="button" class="btn btn-default border btn-sm" (click)="record.private=2">
                                    <span *ngIf="id_type==2">da </span>
                                    <span *ngIf="id_type==1">verso </span>
                                    estero</button>
                                <button [class.alert-info]="record.private==1" type="button" class="btn btn-default border btn-sm" (click)="record.private=1">interna</button>
                            </div>
                            <!-- <select class="form-control form-control-sm" name="private" [(ngModel)]="record.private">
                                <option value="0">da/per Italia</option>
                                <option value="2">da/per estero</option>
                                <option value="1">Interna</option>
                            </select> -->
                        </div>
                    </ng-container>
                </div>
                <div class="row form-group mt-2"  *ngIf="id_type==1" [class.section_disabled]="record.sdi_status==2 || record.sdi_status==4">
                    <div class="col-12">
                        <h5>Ordine d'acquisto</h5>
                    </div>
                    <div class="col-lg-2 mb-1">
                        <label>Numero d'ordine</label>
                    </div>
                    <div class="col-lg-4 mb-1">
                        <input type="text" class="form-control form-control-sm " name="ordernumber" placeholder="..." #ordernumber="ngModel" [(ngModel)]="record.ordernumber"
                                [class.border-danger]="!record.ordernumber && (record.orderdate || record.cup || record.cig)" >
                        <div *ngIf="!record.ordernumber && (record.orderdate || record.cup || record.cig)" class="text-danger"><small>Campo obbligatorio.</small></div>
                    </div>
                    <div class="col-lg-2 mb-1 text-lg-right">
                        <label>data dell'ordine</label>
                    </div>
                    <div class="col-lg-4 mb-1">
                        <input type="date" class="form-control form-control-sm " name="orderdate" placeholder="..." #orderdate="ngModel" [(ngModel)]="record.orderdate">
                    </div>
                    <div class="col-lg-2 mb-1">
                        <label>Codice CUP</label>
                    </div>
                    <div class="col-lg-4 mb-1">
                        <input type="text" class="form-control form-control-sm " name="cup" placeholder="..." #cup="ngModel" [(ngModel)]="record.cup">
                    </div>
                    <div class="col-lg-2 mb-1 text-lg-right">
                        <label>Codice CIG</label>
                    </div>
                    <div class="col-lg-4 mb-1">
                        <input type="text" class="form-control form-control-sm " name="cig" placeholder="..." #cig="ngModel" [(ngModel)]="record.cig">
                    </div>
                </div>
                <div class="row form-group mt-2" [class.section_disabled]="record.sdi_status==2 || record.sdi_status==4">
                    <div class="col-12">
                        <h5>Dati gestionali</h5>
                        <table class="table table-sm">
                            <thead  class="bg-light">
                                <th>Tipologia</th>
                                <th>Valore</th>
                                <th>
                                    <a class="btn btn-xs btn-success float-right" (click)="addManagementValues()"><i class="fa fa-plus"></i></a>
                                </th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let m of record.managementvalues;let i=index">
                                    <td>
                                        <select class="form-control form-control-sm" name="{{i}}_{{m.key}}" [(ngModel)]="m.key" >
                                            <option value="NOTA">NOTA</option>
                                            <option value="TARGA">TARGA</option>
                                            <option value="SCONTRINO">SCONTRINO</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="text" class="form-control form-control-sm" name="{{i}}_{{m.key}}_value" [(ngModel)]="m.value" />
                                    </td>
                                    <td>
                                        <a class="btn btn-xs btn-danger float-right" (click)="deleteManagementValues(i)"><i class="fa fa-trash"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


            <!-- SPEDIZIONE -->
            <div class="tab-pane fade show active" *ngIf="tab==33">
                <div class="form-group row mb-0">
                    <div class="col-lg-2 mb-2">
                        <label>Destinazione</label>
                    </div>
                    <div class="col-lg-10">
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control form-control-sm " name="name_destination" placeholder="..." #name_destination="ngModel" [(ngModel)]="record.name_destination">
                            <div class="input-group-append">
                                <button class="btn btn-default" (click)="searchShop()">Ricerca tra le sedi</button>
                            </div>
                        </div>
                    </div>
                </div>
                <app-cities name="city"  
                    [address]="record.address_destination" 
                    [country]="record.country_destination" 
                    [city]="record.city_destination"  
                    [zip]="record.zip_destination" 
                    (onEntrySelected)="record.address_destination=$event.address;record.city_destination=$event.city;record.country_destination=$event.country;record.zip_destination=$event.zip;"></app-cities>

                <div class="form-group row mt-1">
                    <div class="col-lg-2">
                        <label>Colli</label>
                    </div>
                    <div class="col-lg-2">
                        <input type="number" class="form-control form-control-sm mb-2" name="packages" placeholder="..." #packages="ngModel" [(ngModel)]="record.packages">
                    </div>
                    <div class="col-lg-1 text-lg-right pr-lg-1">
                        <label>Peso</label>
                    </div>
                    <div class="col-lg-3">
                        <div class="input-group input-group-sm mb-2">
                            <input type="number" class="form-control form-control-sm " name="weight" placeholder="..." #weight="ngModel" [(ngModel)]="record.weight">
                            <div class="input-group-append ">
                                <span class="input-group-text">kg</span>
                            </div>
                            <div class="input-group-append">
                                <a class="btn"  title="Calcola n. colli e peso complessivo" (click)="calculateTotalWeightAndPackages()"><i class="fas fa-calculator"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-1 text-lg-right pr-lgl-1">
                        <label>Porto</label>
                    </div>
                    <div class="col-lg-3">
                        <select class="form-control form-control-sm mb-2" name="port"  #transport="ngModel" [(ngModel)]="record.port">
                            <option value="Franco">Franco</option>
                            <option value="Assegnato">Assegnato</option>
                        </select>
                    </div>
                    <div class="col-lg-2">
                        <label>Trasporto a cura di</label>
                    </div>
                    <div class="col-lg-3">
                        <select class="form-control form-control-sm mb-1" name="transport"  #transport="ngModel" [(ngModel)]="record.transport">
                            <option value="Mittente">Mittente</option>
                            <option value="Destinatario">Destinatario</option>
                            <option value="Vettore">Vettore</option>
                        </select>
                    </div>
                    <div class="col-lg-2 text-lg-right pr-lgl-1">
                        <label>Vettore</label>
                    </div>
                    <div class="col-lg-5">
                        <input type="text" class="form-control form-control-sm mb-1" name="causal" placeholder="..." #vector="ngModel" [(ngModel)]="record.vector">
                    </div>
                    <div class="col-lg-2">
                        <label>Data e ora di spedizione</label>
                    </div>
                    <div class="col-lg-3">
                        <input type="text" class="form-control form-control-sm mb-1" name="transport_date" placeholder="..." #transport_date="ngModel" [(ngModel)]="record.transport_date">
                    </div>
                    <div class="col-lg-3 text-lg-right pr-lgl-1">
                        <label>Nr. spedizione</label>
                    </div>
                    <div class="col-lg-4">
                        <input type="text" class="form-control form-control-sm mb-1" name="track_number" placeholder="..." #track_number="ngModel" [(ngModel)]="record.track_number">
                    </div>
                    <div class="col-lg-2">
                        <label>Annotazioni</label>
                    </div>
                    <div class="col-lg-10">
                        <textarea row="3" type="text" class="form-control form-control-sm mb-1" name="transport_note" placeholder="..." #transport_note="ngModel" [(ngModel)]="record.transport_note"></textarea>
                    </div>
                </div>
            </div>


            <!-- PAGAMENTI -->
            <div class="tab-pane fade show active" *ngIf="tab==7">
                <div class="row" [class.section_disabled]="record.sdi_status==2 || record.sdi_status==4">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Metodo di pagamento</label>
                            <select class="form-control form-control-sm" name="methodpayment" placeholder="..." #methodpayment="ngModel" [(ngModel)]="record.methodpayment">
                                <option *ngFor="let m of methodpayments" (value)="m.name">{{m.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Scadenza pagamento</label>
                            <div class="input-group" >
                                <input class="form-control form-control-sm" type="date"  name="paymentschedule"  [(ngModel)]="record.paymentschedule"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6" *ngIf="record.methodpayment=='Bonifico'">
                        <div class="form-group">
                            <label>Banca</label>
                            <div class="input-group input-group-sm">
                                <select class="form-control form-control-sm" name="bank" #bank="ngModel" [(ngModel)]="record.bank" (change)="selectBank()">
                                    <option *ngFor="let b of banks" [value]="b.name">{{b.name}}</option>
                                </select>
                                <div class="input-group-append">
                                    <a type="button" class="btn btn-default" (click)="openModalBanks()" title="Apri gestione banche"><i class="fa fa-cog"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6" *ngIf="record.methodpayment=='Bonifico'">
                        <div class="form-group">
                            <label>IBAN</label>
                            <input type="text" class="form-control form-control-sm" name="iban" placeholder="..." #bank="ngModel" [(ngModel)]="record.iban">
                        </div>
                    </div>
                </div>
                <h5>Lista pagamenti</h5>
                <div *ngIf="id==0" class="m-5 text-center text-secondary">
                    <i class="fas fa-info-circle mr-2"></i>
                    <i>Salvare il documento per poter registrare un pagamento!</i>
                </div>
                <ng-container *ngIf="record.id>0">
                    <app-cash-flow #cashflow (afterLoaded)="changePaid()" [deleteInRow]="true" [filterbox]="false" [id_table]="record.id" [table]="'documents'" [mode]="'embedded'" *ngIf="id != 0"></app-cash-flow>
                    <div class="row">
                        <div class="col-12">
                            <a class="btn btn-success btn-sm mr-1" (click)="addPayment()"><i class="fa fa-plus-circle mr-2"></i>Registra pagamento</a>
                            <a class="btn btn-primary btn-sm mr-1" (click)="LinkPayment()"><i class="fa fa-link mr-2"></i>Collega pagamento</a>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="record.id>0 && isModuleEnabled('deadlines')">                    
                    <h5 class="mt-3">Scadenzario</h5>                  
                    <app-deadlines #deadlines [viewMode]="'table'" [filter_box]="false" [amount]="total.gross" [methodpayment]="record.methodpayment"  [toolbar_action]="true" [no_dateend]="true" [id_table]="record.id" [table]="'documents'" [mode]="'embedded'" *ngIf="id != 0" [reference]="record"></app-deadlines>
                    <div class="row mt-2">
                        <div class="col-12">
                            <a class="btn btn-success btn-sm mr-1" (click)="addDeadlineWithholdingtax()"><i class="fa fa-plus-circle mr-2"></i>Crea scadenza ritenuta</a>
                        </div>
                    </div>
                </ng-container>
            </div>
    

            <!-- DOCUMENTI COLLEGATI -->
            <div class="tab-pane fade show active" *ngIf="tab==5">
                <div class="row">
                    <div class="col-12" >
                        <table class="table table-sm">
                            <thead class="bg-light">
                                <th>Tipo</th>
                                <th>Data</th>
                                <th>Rif</th>
                                <th>Cliente</th>
                                <th>Causale</th>
                                <th>Totale</th>
                                <th>Pagato</th>
                                <th></th>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let doc of record.documentlinked">
                                    <tr *ngIf="!doc['removed']" >
                                        <td>
                                            <div *ngIf="doc.typedocument==1"  title="Fattura" class="badge bg-purple help">FS</div>
                                            <div *ngIf="doc.typedocument==2"  title="Fattura Accompagnatoria" class="badge bg-teal help" >FV</div>
                                            <div *ngIf="doc.typedocument==12"  title="Fattura Acconto" class="badge bg-teal help" >FA</div>
                                            <div *ngIf="doc.typedocument==11"  title="Documento Amministrativo" class="badge bg-olive help" >DA</div>
                                            <div *ngIf="doc.typedocument==3"  title="Nota di Credito" class="badge badge-info bg-pink help">NC</div>
                                            <div *ngIf="doc.typedocument==4"  title="Preventivo" class="badge badge-warning help">PR</div>
                                            <div *ngIf="doc.typedocument==5"  title="Documento di trasporto" class="badge badge-secondary help">DDT</div>
                                            <div *ngIf="doc.typedocument==10"  title="Trasferimento" class="badge badge-secondary help">TR</div>
                                            <div *ngIf="doc.typedocument==6">
                                                <i class="fa fa-info-circle text-gray help mr-2"
                                                    title="Per fare riferimento allo scontrino, esso deve contenere almeno un elemento in comune (stesso Id) con la fattura."></i>
                                                    <span title="Scontrino" class="badge badge-secondary">SC</span>
                                                </div>
                                            <div *ngIf="doc.typedocument==7"  title="Ordine cliente" class="badge badge-secondary help">OC</div>
                                            <div *ngIf="doc.typedocument==8"  title="Ordine fornitore" class="badge badge-secondary help">OF</div>
                                            <div *ngIf="doc.typedocument==9"  title="Non fiscale" class="badge badge-secondary help">NF</div>
                                            <div *ngIf="doc.typedocument==13"  title="Inventario" class="badge bg-navy help" >INV</div>  
                                        </td>
                                        <td>{{doc.date | date:"dd/MM/yyyy"}}</td>
                                        <td>{{doc.reference}} {{doc.referenceprefix}}</td>
                                        <td>{{doc.name}}</td>
                                        <td>{{doc.description}}</td>
                                        <td>{{doc.total | currency:"EUR"}}</td>
                                        <td>{{doc.paid | currency:"EUR"}}</td>
                                        <td class="text-right">                                            
                                            <a class="btn btn-xs btn-secondary mt-1 ml-1" title="Visualizza documento" (click)="openDocument(doc)">
                                                <i class="fas fa-pencil-alt"></i>
                                            </a>                         
                                            <a class="btn btn-xs btn-default mt-1 ml-1"title="Rimuovi collegamento" (click)="removeDocument(doc)">
                                                <i class="fa fa-times "></i>
                                            </a>
                                        </td>
                                    </tr>
                                </ng-container>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="">
                    <!-- <a class="btn btn-sm btn-success" (click)="addDocument()"><i class="fa fa-plus"></i>Aggiungi</a>--> 
                    <a class="btn btn-sm btn-primary" (click)="linkDocument()"><i class="fa fa-link mr-2"></i>Collega documento</a>
                </div>
            </div>


            <!-- NOTE -->
            <!--<div class="tab-pane fade" id="tabDD6" role="tabpanel" aria-labelledby="tab6-tab">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <div class="input-group">
                                <textarea  class="form-control form-control-sm" rows="5" onblur="this.rows=1;" onfocus="this.rows=4;" name="note" [(ngModel)]="record.note" placeholder="..."></textarea>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>-->

        
            <!-- CRONOLOGIA-->
            <div class="tab-pane fade show active" *ngIf="tab==8">
                
                <table class="table table-sm">
                    <thead class="bg-light">
                        <th>Data</th>
                        <th class="text-center">Stato</th>
                        <th>Operatore</th>
                    </thead>
                    <tbody *ngIf="record.history">
                        <tr *ngFor="let h of record.history">
                            <td>{{h.date | date:'dd-MM-yyyy HH:mm:ss'}}</td>
                            <td class="text-center">
                                <span class="badge badge-warning" *ngIf="h.state==1">da confermare</span>
                                <span class="badge badge-success" *ngIf="h.state==2">confermato</span>
                                <span class="badge badge-danger" *ngIf="h.state==3">annullato</span>
                                <span class="badge badge-warning" *ngIf="h.state==4">ordinato</span>
                                <span class="badge badge-warning" *ngIf="h.state==5">spedito</span>
                            </td>
                            <td>{{h.username}}</td>                        
                        </tr>
                    </tbody>
                </table>
            </div>


            <!-- OPERATORE -->
            <div class="tab-pane fade show active" *ngIf="tab==9">
                <div class="row">
                    <div class="col-lg-6">
                        <label>Operatore / Sede</label>
                        <div class="input-group input-group-sm">
                            <select class="form-control form-control-sm" [(ngModel)]="record.id_user" name="id_user" #id_user="ngModel" required>
                                <optgroup >
                                    <ng-container *ngFor="let t of users">
                                        <option *ngIf="t.enabled" [ngValue]="t.id">{{t.name}}</option>
                                    </ng-container>                                    
                                </optgroup>
                                <optgroup label="Non abilitati">
                                    <ng-container *ngFor="let t of users">                                    
                                        <option class="text-gray" *ngIf="!t.enabled"[ngValue]="t.id">{{t.name}}</option>                                
                                    </ng-container>
                                </optgroup>
                            </select>
                            <div *ngIf="id_user.invalid && (id_user.dirty || id_user.touched)" class="alert alert-danger">
                                <div *ngIf="id_user.errors.required">Campo obbligatorio.</div>
                            </div>
                            <div class="input-group-append">
                                <select class="form-control form-control-sm" name="created_from" #created_from="ngModel" [(ngModel)]="record.created_from">
                                    <option *ngFor="let s of shops" [ngValue]="s.id">{{s.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <label>Origine</label>
                        <input type="text" [(ngModel)]="record.origin" name="origin" [disabled]="!user.isSuperUser()" class="form-control form-control-sm" />
                    </div>
                </div>
            </div>


            <!-- SdI -->
            <div class="tab-pane fade show active" *ngIf="tab==10">
                <div class="row">
                    <div class="col-12 my-2">
                        <small>
                            Il <b>Sistema di Interscambio</b>, detto anche SdI, è il sistema informatico gestito dall’Agenzia delle Entrate
                             in grado di ricevere le <b>fatture elettroniche</b>, effettuare i controlli sui dati inseriti e inoltrare le fatture
                              ai destinatari finali. Nello specifico lo SdI controlla il contenuto del file di interscambio in formato XML e ne garantisce l’autenticità e
                               l’integrità, ed effettua controlli mirati sulle dimensioni, il formato e l’autenticità della firma. 
                               Ogni file è identificato da un <b>numero di riferimento</b> ed il Sistema restituisce al mittente lo stato della procedura, gli stati sono:<br>
                               - Da inviare: il file non è stato ancora inviato<br>
                               - Inviato: il file è stato inviato ed è in elaborazione<br>
                               - Consegnato: il file è stato correttamente ricevuto dal sistema ricevente del cliente che sul suo cassetto fiscale<br>
                               - Confermato ma mancata consegna: il file è stato correttamente recapitato solo sul cassetto fiscale del mittente<br>
                               - Errore: il file è stato scartatto perchè non ha superato i controlli (viene indicato il motivo dello scarto), entro 5 giorni il file potrà essere rinviato 
                        </small>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <label>Riferimento SdI</label>
                        <input type="text" class="form-control form-control-sm" name="sdi_idinvoice" [(ngModel)]="record.sdi_idinvoice" />
                    </div>
                    <div class="col-lg-4" *ngIf="record.id_type==1">
                        <label>Stato SdI</label>
                        <div class="input-group input-group-sm">
                            <select class="form-control form-control-sm" name="sdi_status" [(ngModel)]="record.sdi_status">
                                <!-- <option value="0"></option> -->
                                <option value="-1">Da inviare</option>
                                <option value="1">Inviato</option>
                                <option value="2">Consegnato</option>
                                <option value="4">Confermato ma mancata consegna</option>
                                <option value="3">Errore</option>                                
                            </select>
                            <div class="input-group-append">
                                <a type="button" class="btn btn-default" (click)="checkStatusSdiInvoice()"><i class="fas fa-clipboard-check mr-2"></i>Verifica stato</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-3" >
                        <ng-container *ngIf="record.id_type==1 && record.sdi_message && record.sdi_message!='null'">
                            <label>Messaggio SdI</label>
                            <table class="table border table-nowrap table-sm m-0 text-sm">
                                <tbody>
                                    <tr *ngFor="let m of decodeSDIErrorMessage()">
                                        <td>{{m | json}}</td>
                                    </tr>
                                </tbody>
                            </table>                        
                        </ng-container>
                        <textarea *ngIf="user.isSuperUser()" name="sdi_message" class="form-control form-control-sm" row="6" [(ngModel)]="record.sdi_message"></textarea>
                    </div>
                </div>
            </div>
            

            <!--FILES-->
            <div class="tab-pane fade show active" *ngIf="tab==11">
                <app-files [files]="record.files" [directory]="'documents/'+id"></app-files>
                <div class="row">
                    <div class="col-12 text-center mt-3">
                        <ng-container *ngIf="(record.typedocument==1 || record.typedocument==2 || record.typedocument==3)">
                            <ng-container *ngIf="record.sdi_idinvoice">
                                <a type="button" class="ml-2 btn btn-sm btn-primary" (click)="downloadFileSDI(record.id_type==2?'in':'out')"><i class="fa fa-download mr-2"></i>Scarica file dallo SdI</a>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>

            <!--STATISTICA-->
            <div class="tab-pane fade show active" *ngIf="tab==13">
                <table class="table table-sm">
                    <thead class="bg-light">
                        <th></th>
                        <th class="text-center">Quantità</th>
                        <th class="text-right">Vendita</th>
                        <th class="text-right">Costo</th>
                        <th class="text-right">Margine</th>
                        <th></th>
                    </thead>
                    <tr>
                        <td>Prodotti</td>
                        <td class="text-center">{{analytics.products.quantity}}</td>
                        <td class="text-right"><span *ngIf="analytics.products.quantity>0">{{analytics.products.amount | currency:"EUR"}}</span></td>
                        <td class="text-right"><span *ngIf="analytics.products.quantity>0">{{analytics.products.cost | currency:"EUR"}}</span></td>
                        <td class="text-right"><span *ngIf="analytics.products.quantity>0">{{analytics.products.reveneu | currency:"EUR"}}</span></td>
                        <td class="text-right" [class.text-success]="analytics.products.reveneu>=0" [class.text-danger]="analytics.products.reveneu<0">
                            <span *ngIf="analytics.products.quantity>0">{{analytics.products.reveneu/analytics.products.amount*100 | number:'1.0-0'}} %</span>
                        </td>
                    </tr>
                    <tr>
                        <td>Servizi</td>
                        <td class="text-center">{{analytics.services.quantity}}</td>
                        <td class="text-right"><span *ngIf="analytics.services.quantity>0">{{analytics.services.amount | currency:"EUR"}}</span></td>
                        <td class="text-right"><span *ngIf="analytics.services.quantity>0">{{analytics.services.cost | currency:"EUR"}}</span></td>
                        <td class="text-right"><span *ngIf="analytics.services.quantity>0">{{analytics.services.reveneu | currency:"EUR"}}</span></td>
                        <td class="text-right" [class.text-success]="analytics.services.reveneu>=0" [class.text-danger]="analytics.services.reveneu<0">
                            <span *ngIf="analytics.services.quantity>0">{{analytics.services.reveneu/analytics.services.amount*100 | number:'1.0-0'}} %</span>
                        </td>
                    </tr>
                    <tr>
                        <td>Non categorizzati</td>
                        <td class="text-center">{{analytics.notcategorized.quantity}}</td>
                        <td class="text-right"><span *ngIf="analytics.notcategorized.quantity>0">{{analytics.notcategorized.amount | currency:"EUR"}}</span></td>
                        <td class="text-right"><span *ngIf="analytics.notcategorized.quantity>0">?</span></td>
                        <td class="text-right"><span *ngIf="analytics.notcategorized.quantity>0">?</span></td>
                        <td></td>
                    </tr>
                    <tfoot>
                        <tr>
                            <td colspan="2"><b>TOTALE</b></td>
                            <td class="text-right"><b>{{analytics.total.amount | currency:"EUR"}}</b></td>
                            <td class="text-right"><b>{{analytics.total.cost | currency:"EUR"}}</b></td>
                            <td class="text-right"><b>{{analytics.total.reveneu | currency:"EUR"}}</b></td>
                            <td class="text-right" [class.text-success]="analytics.total.reveneu>=0" [class.text-danger]="analytics.total.reveneu<0">
                                <b>{{analytics.total.reveneu/analytics.total.amount*100 | number:'1.0-0'}} %</b>
                            </td>                   
                        </tr>
                    </tfoot>
                </table>
            </div>

        </div>
    </div>
    </form> 
</app-window>
<app-toolbarmodel [model]="this" [showSaveAndClose]="true"></app-toolbarmodel>


<ng-template #modalSelectDocument>
    <div class="modal-header">
        <b>Nuovo documento <span *ngIf="id_type==1">in ingresso</span><span *ngIf="id_type==2">in uscita</span></b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModalSelectDocument()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body row">
        <div class="col-lg-6">
            <button class="btn bg-olive btn-block"      (click)="selectTypeDocument(1);"                             >Fattura</button>
            <button class="btn bg-teal btn-block mt-2" (click)="selectTypeDocument(2);"                              >Fattura accompagnatoria</button>
            <button class="btn bg-lime btn-block mt-2" (click)="selectTypeDocument(12);"                             >Fattura di acconto</button>
            <button class="btn bg-secondary btn-block mt-2" (click)="selectTypeDocument(10);" *ngIf="id_type==1"     >Trasferimento</button>
            <button class="btn bg-secondary btn-block mt-2" (click)="selectTypeDocument(7);" *ngIf="id_type==1"      >Ordine cliente</button>
            <button class="btn bg-secondary btn-block mt-2" (click)="selectTypeDocument(8);" *ngIf="id_type==2"      >Ordine fornitore</button>
            <button class="btn bg-secondary btn-block mt-2" (click)="selectTypeDocument(11);"                        >Documento amministrativo</button>
        </div>
        <div class="col-lg-6">
            <button class="btn bg-maroon btn-block" (click)="selectTypeDocument(3);"                                 >Nota di <span *ngIf="id_type==1">credito</span><span *ngIf="id_type==2">debito</span></button>
            <button class="btn bg-warning btn-block mt-2" (click)="selectTypeDocument(4);"                           >Preventivo</button>
            <button class="btn bg-gray btn-block mt-2" (click)="selectTypeDocument(6);" *ngIf="id_type==1"         >Scontrino</button>
            <button class="btn bg-lightblue btn-block mt-2" (click)="selectTypeDocument(5);"	                     >DDT</button>
            <button class="btn bg-secondary btn-block mt-2" (click)="selectTypeDocument(9);"                         >Non fiscale</button>
            <button class="btn bg-secondary btn-block mt-2" (click)="selectTypeDocument(13);" *ngIf="id_type==1"     >Inventario</button>            
        </div>
    </div>
</ng-template>


<ng-template #modalCausals>
    <div class="modal-header">
        <b>Elenco causali</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModalCausals()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <ul class="list-group">
            <li class="list-group-item"  *ngFor="let d of listDescription">
                <div class="row">
                    <div class="col-10">
                        <input type="text" class="form-control form-control-sm" [(ngModel)]="d.description">
                    </div>
                    <div class="col-2">
                        <button class="btn btn-sm btn-danger float-right" (click)="deleteCausal(d);" title="Elimina"><i class="fa fa-trash-alt"></i></button>
                    </div>
                </div>
            </li>
        </ul>
        <div class="col-12">
            <button class="btn btn-sm btn-block btn-outline-success mt-2" (click)="addCausal()"><i class="fa fa-plus mr-2"></i>Aggiungi</button>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-block btn-secondary" (click)="closeModalCausals()">Chiudi</button>
    </div>
</ng-template>


<ng-template #modalBanks>
    <div class="modal-header">
        <b>Elenco banche</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModalBanks()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <ul class="list-group">
            <li class="list-group-item" *ngFor="let d of banks">              
                <div class="row">
                    <div class="col-4">
                        <input type="text" placeholder="nome banca" class="form-control form-control-sm" [(ngModel)]="d.name">
                    </div>
                    <div class="col-6">
                        <input type="text" placeholder="IBAN" class="form-control form-control-sm" [(ngModel)]="d.iban">
                    </div>
                    <div class="col-2">
                        <button class="btn btn-sm btn-danger float-right" (click)="deleteBank(d);" title="Elimina"><i class="fa fa-trash-alt"></i></button>
                    </div>
                </div>
            </li>
        </ul>
        <div class="col-12">
            <button class="btn btn-sm btn-block btn-outline-success mt-2" (click)="addBank()"><i class="fa fa-plus mr-2"></i>Aggiungi</button>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-block btn-secondary" (click)="closeModalBanks()">Chiudi</button>
    </div>

</ng-template>
<!-- <ng-template #modalRegPayment> 
    <div class="modal-header bg-light">
        <b>Pagamento</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModalRegPayment()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="col-12 text-center my-4">
            <h3>Registrare il pagamento adesso<i class="fas fa-question text-gray fa-2x ml-2"></i></h3>
        </div>
        <div class="col-12 text-center my-4">
            <div>
                <span *ngIf="record.typedocument==1"  title="Fattura" class="badge bg-olive mr-2">FS</span>
                <span *ngIf="record.typedocument==2"  title="Fattura Accompagnatoria" class="badge bg-teal mr-2" >FA</span>
                <span *ngIf="record.typedocument==11" title="Documento Amministrativo" class="badge bg-olive mr-2" >DA</span>
                <span *ngIf="record.typedocument==3"  title="Nota di Credito" class="badge badge-info bg-pink mr-2">NC</span>
                <span *ngIf="record.typedocument==9"  title="Non fiscale" class="badge badge-secondary mr-2">NF</span>
                <span><b>{{record.reference}}{{record.referenceprefix}}</b> del <b>{{record.date | date:"dd/MM/yyyy"}}</b></span><br>
                <span>{{record.addressItem.name}}</span><br>
                <small><i>{{record.description2}}</i></small><br><br>
                <span class="p-2 border">Totale: <b>{{total.gross | currency:"&euro; "}}</b></span>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-sm btn-success" (click)="RegPayment()">Sì. Registra</button>
        <button class="btn btn-sm btn-secondary" (click)="closeModalRegPayment()">No. Grazie</button>
    </div>
</ng-template>


<ng-template #modalEditAddress>
    <div class="modal-header bg-light">
        <b>Modifica Intestazione</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModalEditAddress()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="col-12 text-center my-4">
            <button type="button" class="btn btn-outline-primary btn-block p-4" (click)="addressDisabled=false;closeModalEditAddress()">
                <h5>Modifica solo l'intestazione di questo documento</h5>
                <small>L'intestazione del cliente è corretta, ma questa fattura deve avere una intestazione personalizzata</small>
            </button>
            <button type="button" class="btn btn-outline-primary btn-block p-4 mt-2" (click)="editAddress()">
                <h5>Correggi anche l'anagrafica del cliente</h5>
                <small>L'intestazione del cliente è sbagliata, correggiamo a partire dall'anagrafica</small>
            </button>
        </div>
    </div>
</ng-template> -->