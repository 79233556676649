<section class="content">
    <!-- <div class="mb-1 text-right">
        <button type="button" class="btn btn-sm btn-warning mr-2" (click)="openSettings()"><i class="fas fa-user-cog mr-2"></i>Configura</button>
    </div> -->
    <div class="d-flex justify-content-between">
        <ul class="nav nav-tabs" id="myTab" role="tablist" style="width: calc(100% - 4rem)">        
            <li class="nav-item" *ngFor="let t of types">
                <a class="nav-link" [class.active]="tab==t.id" (click)="tab=t.id">
                    <!-- <i class="fas fa-folder-open"></i> -->
                    <!-- <small class="d-none d-sm-inline-block ml-2">PRATICHE</small><br> -->
                    <!-- <b class="d-none d-sm-inline-block">{{t.name}}</b> -->
                    <i class="fas fa-archive mr-2"></i>{{t.name}}
                </a>
            </li>
        </ul>
        <i class="fas fa-user-cog pointer mr-2" title="Configura" (click)="openSettings()"></i>
    </div>   
    <div class="tab-content" id="myTabContent" >
        <ng-container *ngFor="let t of types">
            <div class="tab-pane show active" *ngIf="tab==t.id">
                <app-dossierlist [mode]="mode" [id_type]="t.id" (selectedRecordOut)="recordSelected=$event" [modalWindow]="modalWindow" [mode]="mode"></app-dossierlist>
            </div>
        </ng-container>        
    </div>
</section>